import {Injectable} from '@angular/core';
import {Apollo,gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {CreatePqrRequestRequestDto} from './dto/create-pqr-request-request.dto';

@Injectable({
	providedIn:'root'
})
export class ContactService{
	private readonly apollo:Apollo;
	
	constructor(apollo:Apollo){
		this.apollo=apollo;
		
	}
	
	createPqrRequest(variables:CreatePqrRequestRequestDto):Observable<any>{
		return this.apollo
		.mutate({
			variables,
			mutation:gql`
          mutation createPqrRequest(
              $name: String!
              $city: String!
              $phone: String!
              $email: String!
              $message: String!
              $recaptchaToken: String!
          ){
              createPqrRequest(
                  name: $name
                  city: $city
                  phone: $phone
                  email: $email
                  message: $message
									recaptchaToken: $recaptchaToken
              ) {
                  createdAt
                  id
              }
          }
			`
		});
	}
}
