<mat-card>
	<mat-card-header>
		<mat-card-title>
			<h1>CONTACTO</h1>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content fxLayout="column" fxLayoutGap="15px">
		<div fxFlex fxFlexAlign="center">
			<p style="max-width: 700px;text-align: center">
				Estamos trabajando día a día para ofrecerte un mejor servicio, abrimos nuestros nuevos canales de
				comunicación para brindarte una atención oportuna según tus necesidades.
			</p>
		</div>
		<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="15px">
			<div fxFlex="60" fxLayout="column">
				<bendita-contact-address-form></bendita-contact-address-form>
			</div>
			<div fxFlex fxLayout="column" fxLayoutGap="15px">
				<ng-container *ngFor="let store of appService.storesObservable | async">
					<mat-card>
						<mat-card-header>
							<mat-card-title>{{store.name}}</mat-card-title>
						</mat-card-header>
						<mat-card-content fxLayout="column">
							<div fxFlex>
								<iframe [src]="appService.domSanitizer.bypassSecurityTrustResourceUrl('https://www.google.com/maps/embed/v1/place?key='+environment.google.apiKey+'&q=place_id:'+store.placeId+'&language=es')" width="100%" height="450px" style="border:0;" allowfullscreen=""></iframe>
								<!--						<google-map	[options]="mapOptions" width="100%"></google-map>-->
								<br>
								<br>
								<p><a href="https://www.google.com/maps/place/?q=place_id:{{store.placeId}}" target="_blank" class="wrapped">{{ store.line1 }}<span *ngIf="store.line2">, {{ store.line2 }}</span></a></p>
								<p>{{ store.city }}, {{ store.state }}, {{ store.country }}</p>
								<p><b>Telefono: </b>+{{appService.phoneNumberUtil.parse(store.phone).getCountryCode()}} {{appService.phoneNumberUtil.parse(store.phone).getNationalNumber()}}</p>
								<p><b>Whatsapp: </b> <a href="https://wa.me/{{store.phone.replace('+','')}}" target="_blank">+{{appService.phoneNumberUtil.parse(store.phone).getCountryCode()}} {{appService.phoneNumberUtil.parse(store.phone).getNationalNumber()}}</a></p>
							</div>
							<div fxFlex>
								<h3>Horarios:</h3>
								<p><b>Lunes a Sábado:</b>  10:00 a. m. a 8:00 p. m.</p>
								<p><b>Domingos:</b>  12:00 p. m. a 6:00 p. m.</p>
							</div>
						</mat-card-content>
					</mat-card>
				</ng-container>
			</div>
		</div>
	</mat-card-content>
</mat-card>
